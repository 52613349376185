<template>
  <div class="m-3">
    <div v-if="isLoading" class="loading loading-big" style="margin-top: 25%"></div>
    <div v-else class="m-0 p-0">
    <div class="d-flex p-3 header-wrapper">
      <p class="my-3 mx-3">
        <label class="mr-3">Customer No:</label>
        <span class="text-black">{{ customerDetail.customerNo }}</span>
      </p>
    </div>
    <div class="my-5 body-wrapper">
      <div class="d-flex col-12 p-0">
        <label class="col-4 p-0">
          Customer Name
        </label>
        <label class="col-4 p-0">
          Ship To Code
        </label>
      </div>
      <div class="d-flex col-12 p-0 my-3">
        <div class="col-4 p-0 pr-5">
          <input v-model="customerDetail.customerName" type="text" class="form-control" disabled>
        </div>
        <div class="col-4 p-0">
          <input v-model="customerDetail.shipToCode" type="text" class="form-control" disabled>
        </div>
      </div>
      <div class="d-flex col-12 p-0 mt-5">
        <label class="col-4 p-0">
          Notification Number
        </label>
        <label class="col-4 p-0">
          Notification Email
        </label>
      </div>
      <div class="d-flex col-12 p-0 my-3">
        <div class="col-4 p-0 pr-5">
          <input v-model="customerDetail.notificationPhoneNo" type="text" class="form-control" disabled>
        </div>
        <div class="col-4 p-0">
          <input v-model="customerDetail.notificationEmail" type="text" class="form-control" disabled>
        </div>
      </div>
      <div class="d-flex col-12 p-0 mt-5">
        <label class="col-4 p-0">
          SMS Notification
        </label>
        <label class="col-4 p-0">
          Email Notification
        </label>
      </div>
      <div class="d-flex col-12 p-0 my-3">
        <div class="col-4 p-0">
          <label class="switch m-auto">
            <input v-model="customerDetail.sendNotificationWhatsapp" name="check-button" type="checkbox" :disabled="!canEdit"/>
            <span class="slider round"></span>
          </label>
        </div>
        <div class="col-4 p-0">
          <label class="switch m-auto">
            <input v-model="customerDetail.sendNotificationEmail" name="check-button" type="checkbox" :disabled="!canEdit"/>
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>
    <div style="padding-bottom: 15rem"></div>
    <div class="page-footer">
      <div class="row justify-content-center">
        <router-link to="/notification-management/1" class="btn btn-back justify-content-center mr-5 px-4">Back to Customer Listing</router-link>
        <b-button v-if="canEdit" @click="handleUpdate()" class="px-5">Update</b-button>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../utils/EventBus";
import cloneDeep from "lodash/cloneDeep"
import * as notificationActions from '../../store/modules/notification/actions'
import * as uiActions from "../../store/modules/_shared/ui/actions"

export default {
  data() {
    return {
      isLoading: false,
      currentUser: this.$store.getters.getUser,
      canEdit: false,
      customerDetail: {},
      originalCustomerDetail: {},
    }
  },
  mounted() {
    this.id = this.$route.params.id
    //Dont allow user to come this page for initial load.
    if (this.$store.state.notification.hasLoadedOnce !== true) {
      this.$router.push({ path: '/notification-management/1' })
      return
    }
    this.canEdit = this.currentUser.isAdmin
    this.customerDetail = this.$store.getters.getCustomerDetail(this.id)
    this.originalCustomerDetail = cloneDeep(this.customerDetail)
    EventBus.$emit('passSubtitles', [this.customerDetail.customerNo])
  },
  methods: {
    handleUpdate() {
      this.isLoading = true
      let payload = {
        customerNo: this.customerDetail.customerNo,
        shipToCode: this.customerDetail.shipToCode,
        // Temporary use back sendNotificationWhatsapp, 
        // might switch back to whatsapp in the future
        sendNotificationWhatsapp: this.customerDetail.sendNotificationWhatsapp,
        sendNotificationEmail: this.customerDetail.sendNotificationEmail,
      }
      this.$store.dispatch(notificationActions.CUSTOMER_POST_REQUEST, payload).then(res => {
        if (res.status == 'error') throw(res)

        let message = `Successfully updated customer notification with customer number = ${res.customerNo}`
        if (res.shipToCode != null) message += ` and ship to code = ${res.shipToCode}`
        
        this.$store.dispatch(uiActions.NOTIFICATION_SUCCESS, { message })
      }).catch(err => {
        this.$store.dispatch(uiActions.NOTIFICATION_ERROR, {
          message: err.errorMessage
        })
      }).finally(() => {
        this.isLoading = false
        this.$router.push({path: '/notification-management/1'
      })})
    }
  }
}
</script>

<style lang="scss" scoped>
.header-wrapper {
  background-color: white;
  text-align: center;
  border-radius: 10px;
  p {
    height: 1rem;
    line-height: 1rem;
  }
}

.custom-select {
  height: 100%;
  border-radius: 10px;
  border: none;
  color: inherit;
}

.btn-back {
  color: #545557;
  border : 1px solid #C2C5D3;
}
</style>